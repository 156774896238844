<template>
  <div class="body">
    <div ref="ceiling" :class="{fixed: isFixed}">
      <Head></Head>
    </div>
    <div class="service-box">
      <div class="service-title-bg">
        <img class="bg1" src="@/image/travelService/travelService-title.jpg" alt="" style="width: 100%"/>
      </div>
      <div class="service-title-bg3" @click="gotuiframe('https://nav.tourgansu.com/')">
        <img class="bg3" src="@/image/travelService/travelService-router.png" alt="" style="width: 100%"/>
      </div>
      <div class="service-title-bg2">
        <div class="prize-video">
          <video-player
              :video-url="videoPath"
              class="video-player vjs-custom-skin"
              ref="videoPlayer"
              :playsinline="false"
              :options="playerOptions"
          >
          </video-player>
        </div>
      </div>
      <Foot></Foot>
    </div>
  </div>
</template>

<script>
import Head from "../Head";
import Foot from "../Foot";
// import 'swiper/dist/css/swiper.css'
//
// import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  name: "prize",
  components: {
    Head,
    Foot,
  },

  data() {
    return {
      isFixed: true,
      videoPath: "",
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            // src: "//path/to/video.mp4", // 路径
            src: "https://vtzn.oss-cn-zhangjiakou.aliyuncs.com/portal/video/shouye/%E5%AE%A3%E4%BC%A0%E7%89%871.mp4", // 路径
            type: "video/mp4", // 类型
          },
        ],
        // poster: "../../static/images/test.jpg", //你的封面地址
        poster: require("@/image/travelService/travelService-video.png"), //你的封面地址

        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
    };
  },
  computed: {

  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    gotuiframe: function (url) {
      this.$router.push("/iframe?url=" + url);
    },
    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      const ceilingEle = this.$refs.ceiling
      if (scrollTop > ceilingEle.offsetTop) {
        this.isFixed = true
      } else {
        this.isFixed = false
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;

}
.service-title-bg2,
.service-title-bg3{
  padding: 2vh 8vw;
}
.prize-video:hover{
  //animation: pulse;
  //animation-duration: 1s;
}

.bg3{
  cursor: pointer;
}
.bg3:hover{
  animation: pulse;
  animation-duration: 1s;
}
/deep/.video-js .vjs-big-play-button {
  top: 40% !important;
  left: 50% !important;
  border-radius: 50% !important;
  height: 3em !important;
  border: none !important;
  line-height: 2.8em !important;
  background-color: rgba(255, 255, 255, 0.5) !important;
}
</style>
